ion-card-header.ios {
  display: flex;
  flex-flow: column-reverse;
}
.header-card-inline {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-card-inline ion-avatar {
  margin-right: 16px;
}
.text-left {
  text-align: left;
}
.swiper .swiper-slide {
  display: block !important;
}
.no-bg {--background: unset !important;}
.bgindex {
  position: relative;
  height: 100px;
}
.bg-index {
  background-image: linear-gradient(135deg, white, lightgreen, darkblue);
  height: 800px;
  width: 800px;
  border-radius: 50%;
  position: absolute;
  top: -567px;
  left: -150px;
}
.customtoolbar {
	--background: unset !important;
	--border-width:0px !important
}
.customtoolbarbutton {
	color:#333
}
.box-thongke {
	width:80%;
    margin:0 auto;
}
.box-thongke .item {
	width:48%;
    height:115px;
	float: left;
	margin:1%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.box-thongke .item .title {
	font-size: 12px;
}
.box-thongke .item h4 {
	margin-top:8px;
	font-weight: 600;
	margin-bottom: 6px;
}
.box-thongke .bg1 {
	background: bisque;
	border-radius: 8px 0px 0px 0px;
}
.box-thongke .bg2 {
	background: bisque;
	border-radius: 0px 8px 0px 0px;
}
.box-thongke .bg3 {
	background: bisque;
	border-radius: 0px 0px 0px 8px;
}
.box-thongke .bg4 {
	background: bisque;
	border-radius: 0px 0px 8px 0px;
}